<template>
    <div ref="scrollTarget"></div>

    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/validate_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("validate_info") }}</span>
        </div>

        <div class="container mt-4">
            <div class="card mb-1">
                <div class="card-header py-3" style="background-color: #E5097F">
                    <h5 class="mb-0" style="color: white; font-weight: bold;"><i class="fa fa-th-list"
                            aria-hidden="true"></i> {{ translate('validate_info') }}</h5>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="text-end">
                                    <router-link to="/import/new_order" type="button" class="btn btn-edit">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> <span
                                            class="button-text">{{ translate('edit') }}</span>
                                    </router-link>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example1"> {{ translate('ship_from')
                                                }}</label>
                                            <input type="text" id="form7Example1" class="form-control"
                                                placeholder="Choose a country" :value="this.$store.state.shipment.origin_country"
                                                style="background-color: #FEEEF6; text-align: center; color: #E5097F; font-weight: bold;"
                                                readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example2"> {{ translate('ship_to')
                                                }}</label>
                                            <input type="text" id="form7Example2" class="form-control"
                                                placeholder="Choose a country" v-model="new_country"
                                                style="background-color: #FEEEF6; text-align: center; color: #E5097F; font-weight: bold;"
                                                readonly>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-md-6 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="text-end">
                                    <router-link to="/import/new_order" type="button" class="btn btn-edit">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> <span
                                            class="button-text">{{ translate('edit') }}</span>
                                    </router-link>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <img src="../assets/img/pack.svg">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example2"></label>
                                            <input type="text" id="form7Example2" class="form-control"
                                                placeholder="Choose a country" v-model="shipment.package_type"
                                                style="background-color: #FEEEF6; text-align: center; color: #E5097F; font-weight: bold;"
                                                readonly>
                                        </div>
                                    </div>
                                </div>
                                <p style="font-weight: bold; text-align: center;"> {{ translate('max_weight') }} {{
                                    shipment.weight }} Kg</p>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center" style="margin-top: 15px;">
                    <div class="col-md-12 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="text-end">
                                    <router-link to="/import/shipment-information" type="button" class="btn btn-edit">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> <span
                                            class="button-text">{{
                                            translate('edit') }}</span>
                                    </router-link>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-1">
                                        <div class="row mb-1">
                                            <div class="form-group">
                                                <label class="form-label">{{ translate('description') }}</label>
                                                <textarea type="text" class="form-control"
                                                    placeholder="Choose a country" rows="2"
                                                    v-model="shipment.description" style="background-color: #FEEEF6;"
                                                    readonly></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-1">
                                        <div class="row mb-1" style="margin-top: 40px; margin-left: 20px;">
                                            <div class="form-group form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="shipment.insure_my_parcel" :disabled="isReadOnly"
                                                    :style="shipment.insure_my_parcel ? { backgroundColor: '#E5097F', border: '#E5097F' } : {}">
                                                <label class="form-check-label" style="margin-left: 12px;">{{
                                                    translate('insure_my_parcel') }}</label>
                                            </div>
                                        </div>
                                        <div class="row mb-1" style="margin-left: 20px;">
                                            <div class="form-group form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="commercial_value" :disabled="isReadOnly"
                                                    :style="commercial_value ? { backgroundColor: '#E5097F', border: '#E5097F' } : {}">
                                                <label class="form-check-label" style="margin-left: 12px;"> {{
                                                    translate('commercial_value') }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 15px;">
                                    <template v-if="commercial_value">
                                        <div class="row mb-4">
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('nature_package') }}</label>
                                                    <input type="text" class="form-control" v-model="item_nature"
                                                        style="background-color: #FEEEF6;" readonly>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('remark') }}</label>
                                                    <input type="text" class="form-control" v-model="item_description"
                                                        style="background-color: #FEEEF6;" readonly>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label">HS Code</label>
                                                    <input type="text" class="form-control" v-model="item_code"
                                                        style="background-color: #FEEEF6;" readonly>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label"> {{ translate('number_items') }}</label>
                                                    <input type="number" class="form-control" v-model="item_number"
                                                        style="background-color: #FEEEF6;" readonly>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('unit_price') }}</label>
                                                    <input type="number" class="form-control" v-model="item_price"
                                                        style="background-color: #FEEEF6;" readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center" style="margin-top: 15px;">
                    <div class="col-md-6 mb-1">
                        <div class="card-body" style="background-color: #F5F5F5; margin: 15px;  position: relative;">
                            <form>
                                <div class="text-end">
                                    <router-link to="/import/sender_information" type="button" class="btn btn-edit">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> <span
                                            class="button-text">{{
                                            translate('edit') }}</span>
                                    </router-link>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label"><b>{{ translate('sender_information')
                                                    }}</b></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-12 mb-1">
                                        <div class="form-group">
                                            <form>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('first_name')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="First Name" v-model="sender.first_name"
                                                                :class="{ 'fill-out': sender.first_name, 'not-fill-out': !sender.first_name }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('last_name')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Last Name" v-model="sender.last_name"
                                                                :class="{ 'fill-out': sender.last_name, 'not-fill-out': !sender.last_name }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('email') }}</label>
                                                            <input readonly type="email" class="form-control"
                                                                placeholder="Email" v-model="sender.email"
                                                                :class="{ 'fill-out': sender.email, 'not-fill-out': !sender.email }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('phone') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Phone"
                                                                :value="sender.extention + sender.phone"
                                                                :class="{ 'fill-out': sender.phone, 'not-fill-out': !sender.phone }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('number') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Number" v-model="sender.number"
                                                                :class="{ 'fill-out': sender.number, 'not-fill-out': !sender.number }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('street') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Street" v-model="sender.street"
                                                                :class="{ 'fill-out': sender.street, 'not-fill-out': !sender.street }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('postal_code')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Postal Code" v-model="sender.postal_code"
                                                                :class="{ 'fill-out': sender.postal_code, 'not-fill-out': !sender.postal_code }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('city') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="City" v-model="sender.city"
                                                                :class="{ 'fill-out': sender.city, 'not-fill-out': !sender.city }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('state') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="State" v-model="sender.state"
                                                                :class="{ 'fill-out': sender.state, 'not-fill-out': !sender.state }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('country') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Country" v-model="sender.country"
                                                                :class="{ 'fill-out': sender.country, 'not-fill-out': !sender.country }">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-md-6 mb-1">
                        <div class="card-body" style="background-color: #F5F5F5; margin: 15px;  position: relative;">
                            <form>
                                <div class="text-end">
                                    <router-link to="/import/receiver_information" type="button" class="btn btn-edit">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> <span
                                            class="button-text">{{
                                            translate('edit') }}</span>
                                    </router-link>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label"><b>{{ translate('receiver_information')
                                                    }}</b></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-12 mb-1">
                                        <div class="form-group">
                                            <form>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('first_name')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="First Name" v-model="receiver.first_name"
                                                                :class="{ 'fill-out': receiver.first_name, 'not-fill-out': !receiver.first_name }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('last_name')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Last Name" v-model="receiver.last_name"
                                                                :class="{ 'fill-out': receiver.last_name, 'not-fill-out': !receiver.last_name }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('email') }}</label>
                                                            <input readonly type="email" class="form-control"
                                                                placeholder="Email" v-model="receiver.email"
                                                                :class="{ 'fill-out': receiver.email, 'not-fill-out': !receiver.email }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('phone') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Phone"
                                                                :value="receiver.extention + receiver.phone"
                                                                :class="{ 'fill-out': receiver.phone, 'not-fill-out': !receiver.phone }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('number') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Number" v-model="receiver.number"
                                                                :class="{ 'fill-out': receiver.number, 'not-fill-out': !receiver.number }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('street') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Street" v-model="receiver.street"
                                                                :class="{ 'fill-out': receiver.street, 'not-fill-out': !receiver.street }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('postal_code')
                                                                }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Postal Code" v-model="receiver.postal_code"
                                                                :class="{ 'fill-out': receiver.postal_code, 'not-fill-out': !receiver.postal_code }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('city') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="City" v-model="receiver.city"
                                                                :class="{ 'fill-out': receiver.city, 'not-fill-out': !receiver.city }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('state') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="State" v-model="receiver.state"
                                                                :class="{ 'fill-out': receiver.state, 'not-fill-out': !receiver.state }">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-1">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ translate('country') }}</label>
                                                            <input readonly type="text" class="form-control"
                                                                placeholder="Country" v-model="receiver.country"
                                                                :class="{ 'fill-out': receiver.country, 'not-fill-out': !receiver.country }">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center" style="margin-top: 20px;">
                <div class="col-md-4 mb-4">
                    <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                        <div class="card-body">
                            <ul class="list-group list-group-flush">

                                <template v-if="insure_my_parcel">
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                        <div>
                                            <strong style="color: #E5097F;"><i class="fa fa-check-circle"
                                                    aria-hidden="true"></i> {{ translate('assurance') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;"> {{ assurance_amount }} <span>&euro;</span></strong></span>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                            <strong style="color: red;"><i class="fa fa-exclamation-triangle"
                                                    aria-hidden="true"></i> {{ translate('no_insurance') }}</strong>
                                        </div>
                                    </li>
                                </template>

                                <template v-if="true">
                                    <template v-if="home_collection">
                                        <li
                                            class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-home"></i> {{
                                                    translate('home_collection') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ home_collection_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-if="shipment.printer_option">
                                        <li
                                            class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-print"></i> {{
                                                    translate('printer') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ shipment.printer_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                </template>

                                <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                    <div>
                                        <strong style="color: #E5097F;">{{ translate('shipment_price') }}</strong>
                                    </div>
                                    <span><strong style="color: #E5097F;"> {{ parseFloat((parseFloat(shipment_price) +
                                        parseFloat(vat_price))).toFixed(2) }} <span v-if="true">&euro;</span></strong></span>
                                </li>

                                <template v-if="discount">
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-2">
                                        <div>
                                            <strong style="color: red;">Discount</strong>
                                        </div>
                                        <span><strong style="color: red;">- {{ discount }} %</strong></span>
                                    </li>
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                        style="margin-top: 20px;">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;">{{ parseFloat(parseFloat(price) * (1 -
                                            parseFloat(discount) / 100)).toFixed(2)
                                                }}
                                                &euro;</strong></span>
                                    </li>
                                </template>
                                <template v-else>
                                    <template v-if="true">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                            </div>
                                            <template v-if="home_collection">
                                                <template v-if="shipment.printer_option">
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount) +
                                                        parseFloat(shipment.printer_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                                <template v-else>
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                            </template>
                                        </li>
                                    </template>
                                </template>
                            </ul>
                            <template v-if="user.is_helpdesk">
                                <button type="button" class="btn btn-next-active btn-lg btn-block"
                                    @click="createShipmentInstance">
                                    <i class="fa fa-credit-card-alt" aria-hidden="true"></i> {{
                                        translate('send_payment_link') }}</button>
                            </template>

                            <template
                                v-if="new_country && price && package_type && weight && description && shipment.service_point_id && sender && receiver">
                                <router-link to="/import/payment" type="button" class="btn btn-next-active btn-lg btn-block">
                                    <i class="fa fa-credit-card-alt" aria-hidden="true"></i> {{
                                        translate('proceed_payment')
                                    }}
                                </router-link>
                            </template>
                            <template v-else>
                                <button type="button" class="btn btn-next-deactive btn-lg btn-block">
                                    <i class="fa fa-credit-card-alt" aria-hidden="true"></i> {{
                                        translate('proceed_payment')
                                    }}
                                </button>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'


export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    data() {
        return {
            new_country: "",
            price: "",
            package_type: "",
            weight: "",
            height: "",
            length: "",
            width: "",
            description: "",
            promo_code: "",
            discount: "",
            service_point_name: "",
            service_point_address: "",
            service_point_email: "",
            service_point_phone: "",
            sender_adderess: "",

            vat_price: "",
            assurance_amount: "",
            VAT: "",
            shipment_price: "",
            insure_my_parcel: "",

            commercial_value: "",
            item_nature: "",
            item_description: "",
            item_code: "",
            item_number: "",
            item_price: "",
            payment_cash: "",

            shipping_option: "",
            assurance: "",
            service: "",
            home_collection: "",
            home_collection_amount: "",

            shipment: {},
            user: {},

            sender: {
                number: "",
                first_name: "",
                last_name: "",
                street: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                email: "",
                phone: ""
            },
            receiver: {
                number: "",
                street: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                email: "",
                phone: ""
            },
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        isReadOnly() {
            return true;
        },

    },
    async created() {
        if (!this.$store.state.shipment.sender.first_name) {
            this.$router.push('/import/sender_information')
        } else {
            if (!this.$store.state.shipment.receiver.first_name) {
                this.$router.push('/import/receiver_information')
            } else {
                if (!this.$store.state.shipment.description || !this.$store.state.shipment.service_point_id || !this.$store.state.shipment.read_conditions || !this.$store.state.shipment.safe_parcel) {
                    this.$router.push('/import/shipment-information')
                }
            }
        }

        this.$store.commit('setIsLoading', true)
        await axios.get('/api/v1/users/me/')
            .then(response => {
                this.user = response.data
            })
            .catch(error => {
                console.log(error)
            })
        this.$store.commit('setIsLoading', false)
    },
    mounted() {
        document.title = 'Validate | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);


        this.shipment = this.$store.state.shipment


        this.new_country = this.translate('belgium')
        this.price = this.$store.state.shipment.price
        this.package_type = this.$store.state.shipment.package_type

        this.promo_code = this.$store.state.shipment.promo_code
        this.discount = this.$store.state.shipment.discount


        this.receiver = this.$store.state.shipment.receiver
        this.sender = this.$store.state.shipment.sender

        this.home_collection = this.$store.state.shipment.home_collection
        this.home_collection_amount = this.$store.state.shipment.home_collection_amount
        this.sender_adderess = this.$store.state.shipment.sender.street + " " + this.$store.state.shipment.sender.number + ", " + this.$store.state.shipment.sender.postal_code + " " + this.$store.state.shipment.sender.city + ", " + this.$store.state.shipment.sender.state + ", " + this.$store.state.shipment.sender.country

        this.item_nature = this.$store.state.shipment.proforma.item_nature
        this.item_description = this.$store.state.shipment.proforma.item_description
        this.item_code = this.$store.state.shipment.proforma.item_code
        this.item_number = this.$store.state.shipment.proforma.item_number
        this.item_price = this.$store.state.shipment.proforma.item_price

        if (this.$store.state.shipment.proforma.item_price) {
            this.commercial_value = true
        } else {
            this.commercial_value = false
        }



        if (this.$store.state.shipment.weight) {
            this.weight = this.$store.state.shipment.weight
        }
        if (this.$store.state.shipment.height) {
            this.height = this.$store.state.shipment.height
        }
        if (this.$store.state.shipment.width) {
            this.width = this.$store.state.shipment.width
        }
        if (this.$store.state.shipment.length) {
            this.length = this.$store.state.shipment.length
        }
        if (this.$store.state.shipment.description) {
            this.description = this.$store.state.shipment.description
        }

        this.getServicePoint()

        this.VAT = this.$store.state.shipment.VAT
        this.assurance_amount = this.$store.state.shipment.assurance_amount
        if (this.$store.state.shipment.insure_my_parcel) {
            this.shipment_price = parseFloat(parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)) - parseFloat(this.assurance_amount)).toFixed(2)
        } else {
            this.shipment_price = parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)).toFixed(2)
        }
        this.vat_price = parseFloat(parseFloat(this.price) - parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100))).toFixed(2)

        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel

    },

    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async setcashpayment(status) {
            console.log('setcashpayment')
            const statuss = status
            this.$store.commit('setcashpayment', statuss)
        },
        async getServicePoint() {
            this.$store.commit('setIsLoading', true)
            await axios.get(`/api/v1/servicepoints/${this.$store.state.shipment.service_point_id}/`)
                .then(response => {
                    this.service_point_name = response.data['company_name']
                    this.service_point_email = response.data['email']
                    this.service_point_phone = response.data['phone']
                    this.service_point_address = response.data['number'] + ", " + response.data['street'] + ", " + response.data['postal_code'] + " " + response.data['city'] + ", " + response.data['state'] + ", " + response.data['country']
                    this.setcashpayment(response.data['cash_payment'])

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        showAlertSuccess() {
            Swal.fire({
                title: this.translate('alert_shipmentinstance'),
                text: this.translate('alert_shipmentinstance_message') + " " + this.shipment.sender.email,
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#4BB543',
            }).then(() => {
                // Redirect to /new_order

            });
        },
        async createShipmentInstance() {
            this.$store.commit('setIsLoading', true)

            const data1 = {
                'country': this.shipment.country,
                'sender_city': this.shipment.sender_city,
                'sender_postal_code': this.shipment.sender_postal_code,
                "receiver_city": this.shipment.receiver_city,
                "receiver_postal_code": this.shipment.receiver_postal_code,
                "package_type": this.shipment.package_type,
                "weight": this.shipment.weight,
                "description": this.shipment.description,
                "insure_my_parcel": this.shipment.insure_my_parcel,
                "printer": this.shipment.printer_option,
                "printer_amount": this.shipment.printer_amount,
                "read_text": this.shipment.read_text,
                "safe_parcel": this.shipment.safe_parcel,
                "read_conditions": this.shipment.read_conditions,
                "assurance_amount": this.shipment.assurance_amount,
                "home_collection": this.shipment.home_collection,
                "home_collection_amount": this.shipment.home_collection_amount,
                "payment_cash": this.shipment.payment_cash,
                "price": this.shipment.price,
                "old_price2": this.shipment.old_price2,
                "VAT": this.shipment.VAT,
                "service_point_id": this.shipment.service_point_id,
                "address_search": this.shipment.address_search,
                "servicepoint_address": this.shipment.servicepoint_address,
                "express": this.shipment.express,
                "s_postal_code": this.shipment.s_postal_code,
                "r_postal_code": this.shipment.r_postal_code,
                "sender": {
                    "number": this.shipment.sender.number,
                    "street": this.shipment.sender.street,
                    "postal_code": this.shipment.sender.postal_code,
                    "city": this.shipment.sender.city,
                    "state": this.shipment.sender.state,
                    "country": this.shipment.sender.country,
                    "email": this.shipment.sender.email,
                    "phone": this.shipment.sender.phone,
                    "extention": this.shipment.sender.extention,
                    "first_name": this.shipment.sender.first_name,
                    "last_name": this.shipment.sender.last_name
                },
                "receiver": {
                    "number": this.shipment.receiver.number,
                    "street": this.shipment.receiver.street,
                    "postal_code": this.shipment.receiver.postal_code,
                    "city": this.shipment.receiver.city,
                    "state": this.shipment.receiver.state,
                    "country": this.shipment.receiver.country,
                    "email": this.shipment.receiver.email,
                    "phone": this.shipment.receiver.phone,
                    "extention": this.shipment.receiver.extention,
                    "first_name": this.shipment.receiver.first_name,
                    "last_name": this.shipment.receiver.last_name
                },
                "proforma": {
                    "item_nature": this.shipment.proforma.item_nature,
                    "item_description": this.shipment.proforma.item_description,
                    "item_code": this.shipment.proforma.item_code,
                    "item_number": this.shipment.proforma.item_number,
                    "item_price": this.shipment.proforma.item_price
                }
            };
            const data2 = {
                'country': this.shipment.country,
                'sender_city': this.shipment.sender_city,
                'sender_postal_code': this.shipment.sender_postal_code,
                "receiver_city": this.shipment.receiver_city,
                "receiver_postal_code": this.shipment.receiver_postal_code,
                "package_type": this.shipment.package_type,
                "weight": this.shipment.weight,
                "description": this.shipment.description,
                "insure_my_parcel": this.shipment.insure_my_parcel,
                "printer": this.shipment.printer_option,
                "printer_amount": this.shipment.printer_amount,
                "read_text": this.shipment.read_text,
                "safe_parcel": this.shipment.safe_parcel,
                "read_conditions": this.shipment.read_conditions,
                "assurance_amount": this.shipment.assurance_amount,
                "home_collection": this.shipment.home_collection,
                "home_collection_amount": this.shipment.home_collection_amount,
                "payment_cash": this.shipment.payment_cash,
                "price": this.shipment.price,
                "old_price2": this.shipment.old_price2,
                "VAT": this.shipment.VAT,
                "service_point_id": this.shipment.service_point_id,
                "address_search": this.shipment.address_search,
                "servicepoint_address": this.shipment.servicepoint_address,
                "express": this.shipment.express,
                "s_postal_code": this.shipment.s_postal_code,
                "r_postal_code": this.shipment.r_postal_code,
                "sender": {
                    "number": this.shipment.sender.number,
                    "street": this.shipment.sender.street,
                    "postal_code": this.shipment.sender.postal_code,
                    "city": this.shipment.sender.city,
                    "state": this.shipment.sender.state,
                    "country": this.shipment.sender.country,
                    "email": this.shipment.sender.email,
                    "phone": this.shipment.sender.phone,
                    "extention": this.shipment.sender.extention,
                    "first_name": this.shipment.sender.first_name,
                    "last_name": this.shipment.sender.last_name
                },
                "receiver": {
                    "number": this.shipment.receiver.number,
                    "street": this.shipment.receiver.street,
                    "postal_code": this.shipment.receiver.postal_code,
                    "city": this.shipment.receiver.city,
                    "state": this.shipment.receiver.state,
                    "country": this.shipment.receiver.country,
                    "email": this.shipment.receiver.email,
                    "phone": this.shipment.receiver.phone,
                    "extention": this.shipment.receiver.extention,
                    "first_name": this.shipment.receiver.first_name,
                    "last_name": this.shipment.receiver.last_name
                },
            };

            if (this.commercial_value) {

                await axios.post('/api/v1/create_shipment_instance/', data1)
                    .then(response => {
                        this.showAlertSuccess()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                await axios.post('/api/v1/create_shipment_instance/', data2)
                    .then(response => {
                        this.showAlertSuccess()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }

            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>


<style>
.btn-edit {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #E5097F;
    border-color: #E5097F;
    border-top-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    color: white;
}

.btn-edit:hover {
    background-color: #9b0656;
    color: white;
}

.button-text {
    display: none;
}

.btn-edit:hover .button-text {
    display: inline;
}

.btn-edit {
    transition: all 0.5s ease-in-out;
}

.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}

.btn-next-active {
    width: 100%;
    margin-top: 20px;
    background-color: #E5097F;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-active:hover {
    background-color: #9b0656;
    color: white;
}

.btn-next-deactive {
    width: 100%;
    margin-top: 20px;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-deactive:hover {
    background-color: gray;
    color: white;
}
</style>