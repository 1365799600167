export default {
    data() {
        return {
            en: {
                shipping_route: "Shipping route",
                ship_from: "Ship from",
                ship_to: "Ship to",
                postal_code: "Postal code",
                choose_country: "Choose a country",
                city: "City",
                shipping_date: "Shipping date",
                choose_package: "Choose your package size",
                belgium: "Belgium",
                other_size: "Other size",
                weight: "Weight (KG)",
                width: "Width (cm)",
                height: "Height (cm)",
                length: "Length (cm)",
                rates_times: "Rates and Times",
                delivery_date: "Delivery date",
                package_type: "Package Type",
                price: "Price (EUR)",
                order: "Order",

                alert1: 'Please choose the delivery country',
                alert2: 'Please choose the delivery postal code',
                alert3: 'Please choose the delivery city',
                alert4: 'Please choose the pick-up postal code',
                alert5: 'Please choose the pick-up city',
                alert6: 'Please choose the shipping date',
                alert7: 'does not have a postal code system',
                alert8: 'Please fill the First Name',
                alert9: 'Please fill the last name',
                alert10: 'Please fill the number address',
                alert11: 'Please fill the street',
                alert12: 'Please fill the postal code',
                alert13: 'Please fill the city',
                alert14: 'Please fill the state',
                alert15: 'Please fill the email',
                alert16: 'Please enter a valid email',
                alert17: 'Please fill the phone',
                alert18: 'Please fill the descirption of your package',
                alert19: 'Please choose a service point',
                alert20: 'Please confirm secure packaging before proceeding',
                alert21: 'Please fill in the necessary fields for customs!',
                alert22: 'Please fill the necessary fields!',
                alert23: 'Promo code is valide, amount : ',
                alert24: 'The promo code is not valide!',
                alert25: 'Could not get statistics !!',
                alert26: 'The dimensions are too big!',
                alert27: 'Choose your pickup option',

                summary: "Summary",
                next: "Next",
                total_amount: "Total amount",
                including_vat: "including VAT",

                sender_information: 'Sender Information',
                first_name: "First Name",
                last_name: "Last Name",
                email: "Email",
                phone: "Phone",
                search_address: "Search Google Maps",
                number: "Number",
                street: "Street",
                state: "State",
                shipping_route_2: "Shipping route (To",
                no_insurance: "No insurance",
                assurance: "Assurance",


                receiver_information: "Receiver Information",


                //shipment Information
                shipment_info: " Shipment Information ",
                address: "Address",
                search: "Find",
                enter_address: "Please enter your address",
                choose_service_point: "Find the nearest service points near you :",
                placeholder_service_point: "Choose a service point to drop-off your parcel",
                description: "Description",
                placeholder_description: "Write a description of your package here...",
                text: "Attention: By checking this, I confirm having secured the packaging for safe transit and I acknowledge my responsibility.",
                assurance_text1: "Parcel insurance",
                assurance_text2: " (Please note that the maximum insurable value for shipments is 1000€)",
                assurance_text2_MA: " (Please note that the maximum insurable value for shipments is 10000 MAD)",
                commercial_text: "Do you have anything to declare to customs?",
                nature: "Nature",
                n_items: "N. items",
                unit_price: "Unit price (€)",
                hs_code: "HS code (optional)",
                remark: "Remark",


                //validation
                validate_info: "Validate information",
                max_weight: "Max weight",
                service_point: "Service Point",
                pickup_address: "Pick-up Address",
                insure_my_parcel: "Insure my parcel",
                commercial_value: "Commercial value",
                shipment_price: "Shipment Price",
                nature_package: "Nature of package",
                edit: "Edit",
                number_items: 'Number of items',
                country: "Country",
                vat: "VAT",
                proceed_payment: "Proceed to Payment",

                //payment
                payment_method: "Payment method",
                pay_credit_card: "Pay with Credit Card",
                pay_bancontact: "Pay with Bancontact",
                pay_cash: "Pay with Cash",
                payment_success: "Payment Successful",
                payment_success_cong: "Congratulations! Your payment has been successfully processed",
                payment_failed: "Payment Failed",
                payment_failed_txt: "Oops! There was an issue processing your payment. Please try again later",

                //payment credit card
                credit_card: "Credit Card Payment",
                enter_promotion_code: "Enter promotion code",
                promo_code: "Promo Code",
                enter_promotion_code_place: "Do you have a promo code? Enter it here",
                validate_code: "Validate code",
                card_inf: "Card information",
                discount: "Discount",

                bancontact: "Bancontact Payment",


                //Cash Payment
                cash: "Cash Payment",
                verification_code: "Verification Code",
                verification_code_place: "Please Enter the verification code",
                validate_payment: "Validate Payment",
                verification_code_failed: "The verification code is not valide!",
                verification_code_txt: 'The code has been sent to the email of the service point you chose. Please contact them to receive the code!',


                //Main nav
                home: "Home",
                new_shipment: "New shipment",
                track_shipment: "Track shipment",
                login: "Login",
                sign_up: "Sign-up",
                myprofile: "My profile",
                sign_out: "Sign-out",
                english: "English",
                french: "French",
                dutch: "Dutch",

                //agent nav
                dashboard: "Dashboard",
                //part nav
                my_orders: "My Orders",



                //Dashboard
                hello: "Hello",
                welcome_back: "Welcome back !",
                tracking_number: "Tracking number",
                order_date: "Order date",
                without_vat: "without VAT",
                payment: "Payment",
                commissions: "Commissions",
                status: "Status",
                not_paid: "Not paid",
                paid: "Paid",
                collected: "Collected",
                cashed: "Cashed",
                credit_card_s: "Credit Card",
                cash_s: "Cash",
                bancontact_s: "Bancontact",
                orders: "Orders",
                my_company: "My Company",
                support: "Support and assistance",
                company_name: "Company Name",
                vat_number: "VAT Number",
                commission: "Commission",
                today_shipment: "Today's shipments",
                shipment_progress: "Shipments in progress",
                shipment_deleivred: "Shipments delivered",
                all_shipment: "All shipments",
                shipments: "Shipments",
                orders_overview: " Orders overview : ",
                profit_this_mounth: "Your profit this month",
                shipments_amount: "Money collected",
                total: "Total",
                pay_bank: "Pay with bank transfer",
                pay_cash: "Pay with cash",
                see_more: "See more",
                withdraw_money: "Withdraw money",
                in_progress: "In progress",
                delivered: "Delivered",
                my_commissions: "My Commissions",
                bank_alert: "Bank transfer payment",
                bank_alert_txt: "The request has been sent successfully to the administration. We will contact you soon !",
                cash_alert: "Cash payment",
                cash_alert_txt: 'The request has been sent successfully to the administration. We will contact you soon !',
                get_money: "Withdraw money",
                get_money_txt: 'The request has been sent successfully to the administration. We will contact you soon !',


                //login page
                forgot_password: "Forgot your Password ?",
                dont_have_account: "You don't have an account ?",
                have_account: "Already have an account ?",
                reset_password: "Reset password",
                login_error: "Email or Password is Incorrect",
                password: "Password",
                confirm_password: "Confirm password",

                no_orders: "Currently, there are no orders on your account",

                Yes: "Yes",
                No: "No",

                //profile
                title: "Title",
                last_login: "Last login",
                mr: "Mr",
                mrs: "Mrs",
                ms: "MS",
                update: "Update",
                update_alert: "Updated successfully!",
                error: "Something went wrong. Please try again",
                update_title: "Update Information",
                update_txt: 'Are you sure you want to update your information ?',
                update_now: "Update now",
                cancel: "Cancel",

                sender: "Sender",
                shipping_label: "Shipping label",

                new_password: "New password",
                confirm_new_password: "Confirm New password",

                reset_password_alert: "Password Updated!",

                send_email: "Send email",
                send_email_alert: 'Email Reset Password sent!',

                track: 'Track',
                nom: "Name",
                shipping_history: "Shipping history",
                receiver: "Receiver",
                date: 'Date',
                location: "Location",
                track_title: 'Incorrect Tracking number',
                track_txt: 'Invalid tracking number. Please try again. Thank you!',


                sender_information2: "Sender",
                receiver_information2: "Receiver",
                shipment_info2: "Shipment",


                register_mssg: "Account created, please log in!",


                //alert assurance
                important_notice: "Important Notice",
                alert_assurance_message: "If you do not choose assurance, you will not be eligible for any refund in case of loss or damage.",
                add_assurance: "Add insurance",
                without_assurance: "Continue without insurance",


                //alert sending instance shipment
                alert_shipmentinstance: "We have sent the payment link to the client",
                alert_shipmentinstance_message: "Please check the following email to proceed to payment : ",
                send_payment_link: "Send payment link ",

                create_new_meeting: "Create new meeting",
                join_your_meeting: "Join your meeting",

                //success
                cong: "Congratulations!",
                package_safe_hands: "Your package is now in good hands.",
                once_your_package: "You can track your package by clicking the button below:",
                track_your_package: "Track your package with two convenient options:",
                thr_whatsapp: "Through WhatsApp",
                thr_sms: "Through SMS",
                thr_website: "On our website",
                whts_desc: "Please send us your tracking number by WhatsApp to:",
                provide_infor: "We will provide you with real-time updates on the status of your package",
                sms_desc: "Please send us your tracking number by text message to:",
                website_desc: "To track your package, please enter the tracking number on our tracking page",
                your_satistfaction: "Do not hesitate to contact us if you need further assistance. Your satisfaction is our priority !",

                business_day: "business day",
                delivery_time: "Delivery time",

                your_address: "Your address",
                service_point_address: "Service point address",
                open_google_maps: "Open in Google Maps",

                welcome_message: "The world at your doorstep in one click!",
                sous_welcome_message: "Welcome to the new era of delivery with Eurêkali",

                change_myAddresse: "No service point nearby? Change your address",

                //footer
                about_us: "About us",
                terms_of_use: "Terms of use",
                privacy_policy: "Privacy Policy",
                accessibility_statement: "Accessibility Statement",
                all_rights_resereved: "All rights reserved",

                home_collection: "Home collection",
                servicepoint_collection: "Eurekali service point",
                servicepoint_collection_text: "Drop off at an Eurekali service point",
                home_collection_text: "Collect the parcel from me",
                drop_off_option: "Choose the pickup location :",

                your_feedback: "Please write your feedback...",
                send: "Send",
                give_feedback: "Please write us a feedback",
                thanks_feedback: "Thank you for your feedback",
                how_was_your_experience: "How was your experience with the new Eurêkali system?",

                call_us: "Call us",
                click_here: "Click here if you need a help",

                //agent home page
                join_us: "Join Us",
                become_agent: "Become a Eurekali agent today and discover how your partnership can propel your business to success!",
                why_agent: "Why are our agents happy to be a Eurekali Point ?",
                parg1_title: "1 - Attract more customers",
                parg1: "Become a Eurekali service point and transform your store into a place of activity for those who need to send. This is an incredible chance to increase traffic and introduce new people to your business.",
                parg2_title: "2 - Increase your income",
                parg2: " As a Eurêkali partner, for each package you manage, you could obtain a commission of 10% (€1.8 to €32 per shipment). It's a simple and effective way to add a new source of income to your business.",
                parg3_title: "3 – Improve your customer service",
                parg3: "Offering Eurêkali services means making your customers' lives easier. Whether they want to send or receive packages nationally or internationally, they will be able to do it without leaving your store. Quality customer service often means loyal customers!",
                parg4_title: "4 – Boost your reputation",
                parg4: "Partnering with Eurekali, a respected brand in the shipping industry, can increase your store's credibility and instill trust in your customers. Proudly display this partnership and enjoy the benefits it can bring to your image.",
                register: "Register",

                //Signup Agent
                personal_information: "Personal information :",
                company_information: "Company information :",
                activity: "Activity",
                store_name: "Store name",

                question_promo_code: "Do you have a promo code ?",
                sale_ticket: "Sales ticket",

                shipments_to_validate: "Pending shipments",
                proforma_invoice: "Proforma invoice",
                validate: "Validate",
                shipment_validated: "The shipment has been validated",
                question_validation: "Are you sure you want to validate this shipment ?",
                shipment_validation: "Shipment validation",
                modify: "Modify",

                paid_price: "Price paid by the customer",
                price_to_pay: "Price to be paid to the agent",
                validate_payment: "Validate the payment",
                package_description: "Package description",
                other_cash: "Cash supplement",
                commission_to_pay: "Agent commissions",
                no_codes: "Currently, there are no requests for verification codes for cash payments",
                client: "Client",
                verification_code: "Verification code",
                amount: "Amount",
                request_date: "Request date",
                cancel: "Cancel",

                my_outstandings: "My outstandings:",
                my_outstandings_text1: "• Your commission will be added automatically, even if the customer orders directly on our site, pays by card and drops off the package in your store.",
                my_outstandings_text2: "• Billing will be done automatically every 15 days.",
                total_to_pay: "Total to pay",
                total_to_reimbursed: "Total to be reimbursed",
                invoice_request: "Request an Invoice",
                request_refund: "Request a refund",
                invoice_request_text: "Are you sure you want to request an invoice ?",
                request_refund_text: "Are you sure you want to request a refund ?",
                invoicev2_created_success: "The invoice was generated successfully",
                refund_created_success: "Refund has been requested",
                my_bills: "My bills :",
                invoice_number: "Reference No.",
                invoice_date: "Billing date",
                amounts: "Amounts",
                status: "Status",
                invoice_in_pdf: "Print",
                my_refunds: "My reimbursements :",
                pay: "Pay",
                invoice_payment: "Bill payment",
                invoice_payment_text: "Please choose your payment type",
                pay_with_card: "Pay by card",
                pay_with_cash: "Pay in cash",

                registration: "Registration",
                registration_text: "Do you want to create a new account as an ?",
                agent: "Agent",
                individual: "Particular",

                details: "Details",
                see_details: "See details",
                payment_type: "Payment type",

                printer_text: "Do you have a printer that allows you to create the shipping label?",
                printer: "Printer",
                read_conditions_alert: "Before proceeding, you must confirm your acceptance of the general terms of use.",
                safe_parcel_alert: "Please confirm that your shipment does not contain any dangerous goods",
                safe_parcel_text: "I declare that my shipment does not contain any dangerous goods:",
                safe_parcel_text_sntc1: "Nor any goods prohibited by the carrier or by the country of destination.",
                safe_parcel_text_sntc2: "See the list of prohibited goods.",
                safe_parcel_text_sntc3: "Any non-compliant shipment may incur liability and block the delivery of your package.",
                safe_parcel_text_sntc4: "Your shipment will not be subject to any compensation.",
                read_conditions_text_sntc1: "I accept",
                read_conditions_text_sntc2: "the general conditions of use of the website eurekali.com",
                read_conditions_text_sntc3: "as well as",
                read_conditions_text_sntc4: "the general conditions of the carrier.",

                packaging: "Packaging",
                packaging_text: "By checking this, I confirm that I have secured the packaging for safe transit and acknowledge my responsibility.",
                important_note: "Important note: ",
                phonenumver_verify_text: "Please check your telephone number, as this is how you will receive your shipment information",
                sendsms: "Confirm",
                sms_confirmation: "Send confirmation by SMS",
                sms_sent_text: "Your shipping information has been successfully transmitted via SMS to your phone number.",
                dont_receive_sms: "I didn't receive the SMS",

                pickup_location: "Pickup location",
                thank_you: "Thank you!",
                we_will_call_you: "We will call you in a few moments",
                call_me: "Call me",

                users_review_text1: "Our customers’ opinions on Eurekali",
                users_review_text2: "Confidence and satisfaction!",

                customs_declaration: "Customs declaration :",

                //products page
                products_page_title: "Prohibited, regulated, and not recommended products for import and export operations.",
                products_paragraphe: "To simplify your shipment, we have listed key products that are prohibited or subject to regulation (please note that this list is not exhaustive). Before each shipment, we recommend checking the carrier's terms and conditions and ensuring that your package does not pose any risks.",
                products_text1: "Make your shipping easier:",
                products_text2: "Here is an essential regulation to take into account:",
                products_text3: "Today, the prohibition or regulation of a commodity depends on several factors, including the mode of transportation, international regulations, the specifics of the country of origin or destination, carrier rules, and the nature of the commodity itself. The goal is to prevent potential risks during transportation, protect people, property, and the environment, and ensure an international standard in the transportation of goods between countries.",
                products_text4: "It is important to note that sending prohibited goods exposes the sender to criminal prosecution in the event of material or bodily harm during transportation. Furthermore, the carrier may choose to block or destroy the goods, with no possibility of claims or compensation.",
                products_text5: "Prohibited goods include:",
                products_text6: "Explosive, dangerous, toxic, flammable, corrosive, or radioactive products such as hand sanitizers, disinfectants, fuels, chemicals, weapons, narcotics, etc.",
                products_text7: "Wet parcels that leak or emit odors.",
                products_text8: "Compressed air products, including aerosols, gas cylinders, and oxygen bottles.",
                products_text9: "Firearms, ammunition, and military equipment.",
                products_text10: "Lithium-ion and lithium-metal batteries, as well as electric vehicles.",
                products_text11: "Human bodies, organs, biological materials, and medical waste.",
                products_text12: "Medications, even with a prescription.",
                products_text13: "Products related to wildlife and flora, plants, living or dead animals, etc.",
                products_text14: "Money and valuable items, such as coins, jewelry, precious metals, etc.",
                products_text15: "Tobacco products, including electronic cigarettes.",
                products_text16: "Perishable food items and products requiring temperature control.",
                products_text17: "Counterfeits, illegal, or immoral products.",
                products_text18: "Polycarbonate baby bottles containing bisphenol A.",
                products_text19: "Shipments with a cylindrical or conical shape, such as rugs or mattresses.",
                products_text20: "Other products with this type of symbol:",
                products_text21: "Goods not recommended include:",
                products_text22: "Fragile items or those with a non-standard packaging shape.",
                products_text23: "Alcoholic beverages (restricted to professional clients with approved specific packaging).",
                products_text24: "Household appliances such as ovens, washing machines, dishwashers, etc.",
                products_text25: "Materials such as ceramics, marble, glass, etc.",
                products_text26: "Musical instruments, electronic equipment, decorative items, car accessories, etc.",
                products_text27: "It is essential to consider these regulations to ensure a safe shipment in accordance with laws and international standards.",

                //conditions pgae
                conditions_page_title: "Terms and Conditions of Website Use",
                conditions_definitions: "1. Definitions:",
                conditions_text1: "Within the context of these terms of use, the following terminology will be used:",
                conditions_text2: '"User" refers to any natural or legal person who wishes to use the services offered by the carriers listed on the website to compare, book, order, and/or purchase or request someone to order and/or purchase a service offered by the carriers listed on the site.',
                conditions_text3: '"Transport" refers to any operation, from the moment the Carrier takes charge of the goods to the delivery at the destination, when a representative of the buying company or another person is present. It also refers to any additional service or option offered by the Carrier, such as parcel insurance or cash-on-delivery payment.',
                conditions_text4: '"Carrier" refers to any transport operator offering the services provided on the website. It also includes postal operators, international and national freight integrators, urban and interurban couriers, specialized express delivery companies, companies offering relay delivery points, and local or regional businesses.',
                conditions_text5: '"Carrier" also refers to the third-party company appointed by EUREKALI to handle the parcel collection, in accordance with these terms of use and the general terms and conditions of the selected carrier.',
                conditions_text6: "2. Purpose and Application of the Terms:",
                conditions_text7: 'Unless otherwise stated in writing, these general terms of use (hereinafter referred to as "the Terms") apply to all users of the www.eurekali.be website (hereinafter referred to as "the Site").',
                conditions_text8: 'The purchasing services are only accessible to users who have fully read and accepted the Terms, the document titled "My Shipping Guide," and the specific conditions of the carriers for each service, prior to placing any order.',
                conditions_text9: 'EUREKALI reserves the right to modify the Terms at any time and without prior notice. It is understood that any such changes will not apply to previously accepted orders.',
                conditions_text10: 'The Site is a tool that allows users to compare transportation rates offered by Carriers at preferential rates.',
                conditions_text11: 'The User is free to choose the Carrier with whom they wish to work.',
                conditions_text12: 'The Site is published by the limited liability company EUREKALI, with its registered office located at 16b Rue des Trois Arbres, 1180 Uccle (Belgium), and registered with the Belgian Register of Legal Entities (BCE) under the number 0537.251.722. Phone number: +32 2 333 37 77, email: info@eurekali.com.',
                conditions_text13: '3. Preliminary Information and Disclaimer Clause:',
                conditions_text14: 'To access the site, the User must have an internet connection and the necessary equipment. The connection costs are the responsibility of the User.',
                conditions_text15: 'EUREKALI does not guarantee the continuity of access to the Site or to any of the services of the Carriers (except for ongoing orders) and cannot be held responsible for the unavailability of the Site or Carrier services. At any time, EUREKALI may decide to update the Site to improve its operation, modify all or part of the Site and its content to make it more responsive to User demands, correct any errors present on the Site, or perform any maintenance operations on the EUREKALI servers that may disrupt access to the Site or any transport service.',
                conditions_text16: "EUREKALI cannot be held responsible for the User's inability to access the Site due to the aforementioned operations or for any reason beyond its control. EUREKALI cannot be held responsible for any interruption of the User's connection at any time or for any failure beyond its control that may prevent the User from completing any operations.",
                conditions_text17: "In the event that the malfunction is caused by the operation of the Site, the User will acknowledge that technically, the proper functioning of the Site may depend on factors that are not entirely under the control of EUREKALI.",
                conditions_text18: "EUREKALI does not guarantee that the site is free from computer viruses or other anomalies beyond its control. Nevertheless, EUREKALI will take all possible measures to ensure Users a secure use of the Site.",
                conditions_text19: "Therefore, EUREKALI cannot be held responsible for any damage or loss due to system errors or interruptions affecting the proper functioning of the Site and the online display of Carrier services.",
                conditions_text20: "Furthermore, in the event of unlawful use or use contrary to the Terms, EUREKALI reserves the right to deny access to the Site at any time and without prior notice.",
                conditions_text21: "4. Use of the Site:",
                conditions_text22: "EUREKALI exclusively offers through its Site an automated comparison service for detailed transportation rates of goods (excluding moving services) provided by the carriers it selects, in a non-exclusive and non-exhaustive manner.",
                conditions_text23: 'The complete service ordering steps are explained in the document "My Shipping Guide," which the User acknowledges after reading and accepting it.',
                conditions_text24: "The transport contract is directly concluded between the User and the Carrier. EUREKALI invoices the User for the entire range of services.",
                conditions_text25: 'EUREKALI is authorized by the company CIT Express (whose registered office is located at 16b Rue des Trois Arbres, 1180 Uccle, Belgium, and registered with the BCE under the number 0537.251.722) to, on behalf of and for the account of CIT Express, conclude with the User, under the Terms, the free mandate through which CIT Express will, in turn, conclude each transport contract between the carrier selected by the User and the User, on behalf and for the account of whom CIT Express will act (under the free mandate given by the User, itself concluded by EUREKALI as an authorized agent of CIT Express, in accordance with the above).',
                conditions_text26: 'EUREKALI is also authorized by CIT Express to ensure that any invoices to the User are issued at the price chosen by the User and to collect the amounts due. Subject to the full payment of the amounts owed by the User, EUREKALI guarantees payment to the Carrier by the User.',
                conditions_text27: "EUREKALI draws the User's attention to the general terms of the selected Carrier that apply to their order. The terms and conditions of the Carriers can be found (here). The User agrees to comply with these conditions, especially to accept the liability limitations regarding transport services, as EUREKALI has no liability in this regard.",
                conditions_text28: "The User expressly waives any legal or extrajudicial action, claim, in full, in warranty, or cross-action, directly or indirectly, against EUREKALI in connection with the services of the Carriers.",
                conditions_text29: "The User acknowledges that the pickup of the shipment may be carried out by a third-party company for reasons of efficiency, without EUREKALI accepting any responsibilities other than those described in the Terms or the general conditions of the selected Carrier.",
                conditions_text30: "The User waives the right to rely on any document that may be supplementary or contrary to the Terms or the general conditions of the carriers.",
                conditions_text31: "EUREKALI cannot guarantee to the User the existence, accuracy, or availability of any transportation offer displayed on the site. The offers will be provided to EUREKALI under the sole responsibility of the Carriers and subject to the Carrier's acceptance of orders.",
                conditions_text32: "5. Customer Account:",
                conditions_text33: 'The User can create a "customer account," although it is not mandatory to do so to use the Site.',
                conditions_text34: "To create a customer account, the User must provide a valid email address and a password. The User is responsible for the confidentiality of their username and password and for any actions that may be taken from their account using their username and/or password.",
                conditions_text35: "The User expressly agrees to immediately inform EUREKALI of any unauthorized use of their account and/or password and/or any issue that may disrupt their security. EUREKALI cannot, under any circumstances, be held responsible for any loss or damage resulting from the breach of obligations under this paragraph.",
                conditions_text36: "6. Modification and Cancellation of the Order by the User:",
                conditions_text37: "No cancellation or modification will be possible after the order confirmation on the site. The User acknowledges that the 14-day withdrawal period provided by the Belgian law of April 6, 2010, on market practices and consumer protection does not apply to orders covered by these Terms.",
                conditions_text38: "7. Rates and Pricing:",
                conditions_text39: "The rates offered by the Carriers are stated in Euros, all rights and taxes included, and may be changed at any time without prior notice. Only the price confirmed at the time of the order will be valid.",
                conditions_text40: "However, the prices of transport services offered through the Site are not subject to change after the confirmed order is placed.",
                conditions_text41: "In general, and unless otherwise stated, any other service that is not expressly mentioned in the order confirmation is not included in the price. Likewise, any applicable fees and taxes of any kind will be the responsibility of the User or the destination contact, in accordance with the applicable regulations.",
                conditions_text42: "It is therefore the responsibility of the User to gather information on duties and taxes, including customs tariffs, that may apply to the shipment, as no such information can be provided to the User as part of the order.",
                conditions_text43: 'In the event that an additional payment should be applied for any extra costs incurred during the ordering process, listed in the "additional fees" section of "My Shipping Guide," the User will be notified directly by email. The order will be put on hold until the payment of the price supplement is complete.',
                conditions_text44: "In the event that the weight of the package is significantly higher than that communicated during the order, penalties will be calculated according to the table below. If the weight and volume are higher than those communicated, the heaviest penalty between the two must be applied.",
                conditions_text45: "Download all the terms of use and service of the site in PDF format.",

                send_my_parcel: "Send my parcel",
                dimensions_alert: "Please enter the dimensions of your parcel",
                verify_postalcode: "Please make sure that the postal codes are correct",
                same_day_delivery: "Same day delivery",
                same_day: "Same day",
                choose_this_offer: "Choose this offer",
                ultra_fast: "Ultra fast",
                ultra_fast_text: "With the Ultra Fast option, same-day pickup and delivery",

                my_space: "My space",

                delivery_estimate: "Delivery estimate",
                pickup_time_phrase: "My preferred pickup time is between:",
                delivery_date_pickup_time: "Delivery date and pickup time",
                from_pickuptime: "From",
                to_pickuptime: "To",
                validate_order: "Validate order",
                ship_date_alert: "Please fill the shipping date",
                pickup_time_alert: "Please fill the pickup time",
                shipment_modified: "The shipment has been modified",

                confirm: "Confirm",
                bravo: "BRAVO!",
                bravo_text1: "Your package is ready to be sent.",
                bravo_text2: "All that's left is to confirm your phone number to receive the address of the nearest drop-off point to you.",
                printing_documents: "Printing documents",
                print_shippinglabel: "Print shipping label",
                print: "Print",
                print_saleticket: "Print sales ticket",
                print_proformainvoice: "Print proforma invoice",
                pack_itmes: "Pack items securely in provided transporter packaging.",
                affix_itmes1: "Affix shipping label and proforma invoice securely to the package",
                affix_itmes2: "Affix shipping label securely to the package",
                complete: "Complete",

                home_success_text1: "Congratulations!",
                home_success_text2: "Your package is ready to be shipped.",
                home_success_text3: "Please consider the following points to finalize the shipping process:",
                step1_text: "Please confirm your phone number to receive the drop-off point address.",
                step2_text: "Please ensure your package is properly packed and secured to ensure its safety during delivery.",
                step3_text: "Please drop off your package at the designated drop-off point you previously selected.",
                step4_text: "In a few moments, once you have received the shipping label by email, please print it and attach it to your package.",

                contact_us_problem: "If you encounter any issues, please contact us at the following number:",
                contact_us_problem_phone: "0032 2 333 37 77",

                le_volume: "Volume",
                see_the_list: "See the list of prohibited goods:",
                call_us_questions: "Do not hesitate to call us if you have any questions:",
                modal_title: "Shipment Control and Management",
                modal_title_desc: "This page gives you full control over the shipment. You can validate, modify, or reject it based on the provided details or the nature of the shipment.",

                inactive_account: "Your account is inactive. Please resolve your situation",

                meetings_error: "A meeting room with this name already exists.",
                delete_room: "Delete existing room",

                bonus_title: "Welcome to Eurêkali!",
                bonus_subtitle: "Earn loyalty points with every shipment to enjoy exceptional benefits.",
                my_points: "My points",
                remarks_bonus: "Remarks",
                new_client_bonus: "New agent referral bonus =",
                new_client_points_bonus: "15 points",
                edit_shipment_bonus: "Shipment edit bonus =",
                edit_shipment_points_bonus: "5 points",
                new_shipment_bonus: "New shipment bonus =",
                new_shipment_points_bonus: "5 points",
                fivepoints_bonus: "5 points =",
                fivepoints_points_bonus: "1€",
                bonus_title2: "Spend my loyalty points",
                bonus_subtitle2: "Redeem your loyalty points for a voucher",
                order_bonus: "Order",
                bonus: "Bonus",
                bonus_button: "Gift Refund",
                bonus_alert: "We have received your request, Thank you",

                min_points: "You need a minimum of 250 points to order a gift.",

                parrainage_title: "Bonus Boost!",
                parrainage_stitle: "Each new assistance you recommend and successfully persuade a new agent to join our system will earn you 100 bonus points!",
                parrainage_stitle2: "Simply fill out the form below to enable us to contact the interested agent and facilitate their registration.",
                parrainage_your_info: "Your Information:",
                parrainage_your_name: "Your full name",
                parrainage_your_email: "Your email",
                parrainage_agent_info: "New Agent's Information:",
                parrainage_agent_name: "Full Name",
                parrainage_agent_address: "Address",
                parrainage_agent_email: "Email Address",
                parrainage_send: "Send",

                parrainage_not_txt1: "Thank you for your assistance!",
                parrainage_not_txt2: "When we contact the new agent and proceed with their registration, your bonuses will be automatically added to your account.",

                morocco: "Morocco",

                location_choose_title: "Choose Your Location",
                location_choose_desc: "Please choose your current location",

                coworking_signup_title1: "Welcome to Eurêkali Point",
                coworking_signup_title2: "Transform your coworking space",
                coworking_signup_title3: "By joining our network of service points now!",

                contact: "Contact",

                ship_serv_export: "Export",
                ship_serv_import: "Import"





            }
        }
    }
}
