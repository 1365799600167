import { createRouter, createWebHistory } from 'vue-router'
import SignUp from '../views/SignUp.vue'
import SignUpAgent from '../views/SignUpAgent.vue'
import LogIn from '../views/LogIn.vue'
import MyProfile from '../views/MyProfile.vue'
import ResetPassword from '../views/ResetPassword.vue'
import PasswordConfirm from '../views/PasswordConfirm.vue'
import store from '../store'
import NewOrder from '../views/NewOrder.vue'
import ShipmentInformation from '../views/ShipmentInformation.vue'
import Information from '../views/Information.vue'
import SenderInformation from '../views/SenderInformation.vue'
import ReceiverInformation from '../views/ReceiverInformation.vue'
import Validate from '../views/Validate.vue'
import Payment from '../views/Payment.vue'
import CreditCardPayment from '../views/CreditCardPayment.vue'
import CashPayment from '../views/CashPayment.vue'
import BancontactPayment from '../views/BancontactPayment.vue'
import Success from '../views/Success.vue'
import MyOrders from '../views/MyOrders.vue'
import Tracking from '../views/Tracking.vue'
import ProgressOrders from '../views/ProgressOrders.vue'
import templatedesigne from '../views/templatedesigne.vue'
import Dashboard from '../views/Dashboard.vue'
import Company from '../views/Company.vue'
import Commissions from '../views/Commissions.vue'
import Meetings from '../views/Meetings.vue'
import MeetingsRoom from '../views/MeetingsRoom.vue'
import GetInfo from '../views/GetInfo.vue'
import Agent from '../views/Agent.vue'
import OrdersValidate from '../views/OrdersValidate.vue'
import ModifyShipment from '../views/ModifyShipment.vue'
import CashCodes from '../views/CashCodes.vue'
import Products from '../views/Products.vue'
import Conditions from '../views/Conditions.vue'
import ValidateShipping from '../views/ValidateShipping.vue'
import DeliveredOrders from '../views/DeliveredOrders.vue'
import PrintDocuments from '../views/PrintDocuments.vue'
import AgentBonus from '../views/AgentBonus'
import ParrainageAgent from '../views/ParrainageAgent.vue'
import SignUpAgentMa from '../views/SignUpAgentMa.vue'
import SignUpCoworking from '../views/SignUpCoworking.vue'
import SignUpCoworkingMa from '../views/SignUpCoworkingMa.vue'
import NewOrder_Import from '@/views/NewOrder_Import.vue'
import SenderInformation_Import from '@/views/SenderInformation_Import.vue'
import ReceiverInformation_Import from '@/views/ReceiverInformation_Import.vue'
import ShipmentInformation_Import from '@/views/ShipmentInformation_Import.vue'
import Validate_Import from '@/views/Validate_Import.vue'
import Payment_Import from '@/views/Payment_Import.vue'
import CreditCardPayment_Import from '@/views/CreditCardPayment_Import.vue'
import BancontactPayment_Import from '@/views/BancontactPayment_Import.vue'






const routes = [
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/signup-agent',
    name: 'SignUpAgent',
    component: SignUpAgent
  },
  {
    path: '/agent',
    name: "Agent",
    component: Agent
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/myprofile/',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/password/reset/confirm/:uid/:token/',
    name: 'PasswordConfirm',
    component: PasswordConfirm
  },
  {
    path: '/new_order',
    name: 'NewOrder',
    component: NewOrder
  },
  {
    path: '/shipment-information',
    name: 'ShipmentInformation',
    component: ShipmentInformation
  },
  {
    path: '/information',
    name: 'Information',
    component: Information
  },
  {
    path: '/sender_information',
    name: 'SenderInformation',
    component: SenderInformation
  },
  {
    path: '/receiver_information',
    name: 'ReceiverInformation',
    component: ReceiverInformation
  },
  {
    path: '/validate',
    name: 'Validate',
    component: Validate
  },
  {
    path: '/validate/:shipment_id',
    name: 'GetInfo',
    component: GetInfo
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: Conditions
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/payment/creditcard',
    name: 'CreditCardPayment',
    component: CreditCardPayment
  },
  {
    path: '/payment/bancontact',
    name: 'BancontactPayment',
    component: BancontactPayment
  },
  {
    path: '/payment/bancontact/:shipment_id',
    name: 'BancontactPaymentsh',
    component: BancontactPayment
  },
  {
    path: '/dashboard/:invoice_id',
    name: 'InvoicePayment',
    component: Dashboard
  },
  {
    path: '/payment/cash',
    name: 'CashPayment',
    component: CashPayment
  },
  {
    path: '/success/:shipment_id',
    name: 'Success',
    component: Success
  },
  {
    path: '/myorders',
    name: 'MyOrders',
    component: MyOrders,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/track',
    name: 'Tracking1',
    component: Tracking
  },
  {
    path: '/track/:shipment_id',
    name: 'Tracking2',
    component: Tracking
  },
  {
    path: '/designe',
    name: 'templatedesigne',
    component: templatedesigne
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/progress-orders',
    name: 'ProgressOrders',
    component: ProgressOrders,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/delivered-orders',
    name: 'DeliveredOrders',
    component: DeliveredOrders,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/orders-validate',
    name: 'OrdersValidate',
    component: OrdersValidate,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/cash-codes',
    name: 'CashCodes',
    component: CashCodes,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/edit-shipment/:shipment_id',
    name: 'ModifyShipment',
    component: ModifyShipment,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: Commissions,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: NewOrder
  },
  {
    path: '/meetings',
    name: 'Meetings',
    component: Meetings
  },
  {
    path: '/meetingsroom/:room_name/:lang',
    name: 'MeetingsRoom',
    component: MeetingsRoom
  },
  {
    path: '/validate-shipment/:shipment_id',
    name: 'ValidateShipping',
    component: ValidateShipping,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/print-documents/:shipment_id',
    name: 'PrintDocuments',
    component: PrintDocuments,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/mybonus',
    name: 'AgentBonus',
    component: AgentBonus,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/parrainage',
    name: 'ParrainageAgent',
    component: ParrainageAgent,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/signup-agent-ma',
    name: 'SignUpAgentMa',
    component: SignUpAgentMa,
  },
  {
    path: '/signup-coworking',
    name: 'SignUpCoworking',
    component: SignUpCoworking,
  },
  {
    path: '/signup-coworking-ma',
    name: 'SignUpCoworkingMa',
    component: SignUpCoworkingMa,
  },
  {
    path: '/import/new_order',
    name: 'NewOrder_Import',
    component: NewOrder_Import
  },
  {
    path: '/import/sender_information',
    name: 'SenderInformation_Import',
    component: SenderInformation_Import
  },
  {
    path: '/import/receiver_information',
    name: 'ReceiverInformation_Import',
    component: ReceiverInformation_Import
  },
  {
    path: '/import/shipment-information',
    name: 'ShipmentInformation_Import',
    component: ShipmentInformation_Import
  },
  {
    path: '/import/validate',
    name: 'Validate_Import',
    component: Validate_Import
  },
  {
    path: '/import/payment',
    name: 'Payment_Import',
    component: Payment_Import
  },
  {
    path: '/import/payment/creditcard',
    name: 'CreditCardPayment_Import',
    component: CreditCardPayment_Import
  },
  {
    path: '/import/payment/bancontact',
    name: 'BancontactPayment_Import',
    component: BancontactPayment_Import
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
