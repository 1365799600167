<template>
    <div ref="scrollTarget"></div>

    <section style="margin-top: 30px; margin-bottom: 100px;">


        <ProcessTimeLine2 />

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/shipment_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("shipment_info") }}</span>
        </div>

        <div class="container mt-4">
            <div v-if="isLoading" class="loading-overlay2">
                <img src="@/assets/img/LOADING.gif" style="scale: 0.7 ;" alt="Loading Animation" />
            </div>
            <div>
                <div class="row justify-content-center">
                    <div class="col-md-8 mb-4">

                        <div class="card mb-4" style="border-radius: 20px;">
                            <div class="card-header py-3"
                                style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white; font-weight: bold;"><img
                                        src="../assets/img/shipment_info_icon.svg"
                                        style="transform: scale(1.5); margin-right: 8px; margin-left: 5px;"> {{
                                            translate('shipment_info') }} </h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row mb-4">
                                        <div class="form-group mb-1 col-12">

                                            <div class="form-group mb-4">
                                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                                    translate('description') }} : </label>
                                                <textarea class="form-control"
                                                    :class="{ 'fill-out': description, 'not-fill-out': !description }"
                                                    rows="2" :placeholder="translate('placeholder_description')"
                                                    v-model="description"></textarea>
                                            </div>

                                            <div class="form-group mb-4 col-12" style="margin-top: 5px;">
                                                <span style="color: black; font-weight: 600;">{{ translate('packaging') }} :
                                                </span>
                                                <div class="form-check" style="margin-top: 10px; color: #E5097F; background-color: #FEEEF6; border: 1px solid #E5097F; border-radius: 10px; cursor: pointer;">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault" v-model="read_text" style="cursor: pointer;"
                                                        :style="read_text ? { backgroundColor: '#E5097F', border: '#E5097F' } : { backgroundColor: '#aaa', border: '#E5097F' }">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        <span style="font-weight: bold;">{{ translate('important_note')
                                                        }}</span>{{ translate('packaging_text') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <template v-if="true">
                                            <div class="form-group mb-1 col-12">
                                                <div class="form-group">
                                                    <label class="form-check-label text-center text-md-start">
                                                        <img src="../assets/img/printer.svg" style="transform: scale(0.8);">
                                                        {{ translate('printer_text') }}
                                                    </label>

                                                    <div class="text-center text-md-start" style="margin-left: 40px;">
                                                        <div class="form-check form-check-inline" style="margin-left: 10px;"
                                                            @click="unchoosePrinter">
                                                            <input class="form-check-input" type="radio" name="printer"
                                                                id="printer2" style="cursor: pointer;"
                                                                :checked="!printer_option"
                                                                :style="!printer_option ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                            <label class="form-check-label" for="printer2"
                                                                style="cursor: pointer;">
                                                                {{ translate('Yes') }}
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline" @click="choosePrinter">
                                                            <input class="form-check-input" type="radio" name="printer"
                                                                id="printer1" style="cursor: pointer;"
                                                                :checked="printer_option"
                                                                :style="printer_option ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                            <label class="form-check-label" for="printer1"
                                                                style="cursor: pointer;">
                                                                {{ translate('No') }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                        <div class="form-group mb-1 col-12">
                                            <div class="form-group">
                                                <label class="form-check-label text-center text-md-start">
                                                    <img src="../assets/img/insurance.svg" style="transform: scale(0.7);">
                                                    {{ translate('assurance_text1') }}. 
                                                    <span v-if="this.$store.state.shipment.country == 'BE'" style="font-style: italic;">{{ translate('assurance_text2') }}</span>
                                                    <span v-else style="font-style: italic;">{{ translate('assurance_text2_MA') }}</span>
                                                </label>

                                                <div class="text-center text-md-start" style="margin-left: 40px;">
                                                    <div class="form-check form-check-inline" @click="chooseAssurance">
                                                        <input class="form-check-input" type="radio" name="insurance"
                                                            id="insurance1" style="cursor: pointer;"
                                                            :checked="insure_my_parcel"
                                                            :style="insure_my_parcel ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                        <label class="form-check-label" for="insurance1"
                                                            style="cursor: pointer;">
                                                            {{ translate('Yes') }}
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline" style="margin-left: 10px;"
                                                        @click="unchooseAssurance">
                                                        <input class="form-check-input" type="radio" name="insurance"
                                                            id="insurance2" style="cursor: pointer;"
                                                            :checked="!insure_my_parcel"
                                                            :style="!insure_my_parcel ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                        <label class="form-check-label" for="insurance2"
                                                            style="cursor: pointer;">
                                                            {{ translate('No') }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <template v-if="VAT === '0'">
                                            <template v-if="$store.state.shipment.weight === 0.5">
                                                <div class="form-group mb-1 col-12 text-center text-md-start"
                                                    style="margin-top: 15px;">
                                                    <div class="form-group">
                                                        <label class="form-check-label">
                                                            <img src="../assets/img/proform.svg"
                                                                style="transform: scale(0.7);">
                                                            {{ translate('commercial_text') }}
                                                        </label>

                                                        <div style="margin-left: 40px;">
                                                            <div class="form-check form-check-inline"
                                                                @click="chooseCommercialvalue">
                                                                <input class="form-check-input" type="radio"
                                                                    name="commercialvalue" id="commercialvalue1"
                                                                    style="cursor: pointer;" :checked="commercial_value"
                                                                    :style="commercial_value ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                                <label class="form-check-label" for="commercialvalue1"
                                                                    style="cursor: pointer;">
                                                                    {{ translate('Yes') }}
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline"
                                                                style="margin-left: 10px;" @click="unchooseCommercialvalue">
                                                                <input class="form-check-input" type="radio"
                                                                    name="commercialvalue" id="commercialvalue2"
                                                                    style="cursor: pointer;" :checked="!commercial_value"
                                                                    :style="!commercial_value ? { backgroundColor: '#4FEB6E', border: '#4FEB6E' } : {}">
                                                                <label class="form-check-label" for="commercialvalue2"
                                                                    style="cursor: pointer;">
                                                                    {{ translate('No') }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <template v-if="commercial_value">
                                                    <div class="row mb-4">
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <select class="form-control" v-model="item_nature"
                                                                    :class="{ 'fill-out': item_nature, 'not-fill-out': !item_nature }">
                                                                    <option value="" disabled selected>{{
                                                                        translate('nature') }}
                                                                    </option>
                                                                    <option value="Document">Document</option>
                                                                    <option value="Commercial">Commercial</option>
                                                                    <option value="Gift">Gift</option>
                                                                    <option value="Sample">Sample</option>
                                                                    <option value="Personal use">Personal use</option>
                                                                    <option value="Personal effects">Personal effects
                                                                    </option>
                                                                    <option value="Repair & return">Repair & return</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="text" class="form-control"
                                                                    v-model="item_description"
                                                                    :placeholder="translate('remark')"
                                                                    :class="{ 'fill-out': item_description, 'not-fill-out': !item_description }">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="text" class="form-control" v-model="item_code"
                                                                    :placeholder="translate('hs_code')"
                                                                    :class="{ 'fill-out': item_code, 'not-fill-out': !item_code }">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="number" class="form-control"
                                                                    v-model="item_number"
                                                                    :placeholder="translate('n_items')"
                                                                    :class="{ 'fill-out': item_number, 'not-fill-out': !item_number }"
                                                                    @input="validateItemsNumber">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="number" class="form-control"
                                                                    v-model="item_price"
                                                                    :placeholder="translate('unit_price')"
                                                                    :class="{ 'fill-out': item_price, 'not-fill-out': !item_price }"
                                                                    @input="validateItemsPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>

                                            <template v-else>
                                                <div class="form-group mb-1 col-12" style="margin-top: 15px;">
                                                    <div class="form-group">
                                                        <label class="form-check-label">
                                                            <img src="../assets/img/proform.svg"
                                                                style="transform: scale(0.7);">
                                                            {{ translate('customs_declaration') }}
                                                        </label>

                                                    </div>
                                                </div>

                                                <template v-if="commercial_value">
                                                    <div class="row mb-4">
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <select class="form-control" v-model="item_nature"
                                                                    :class="{ 'fill-out': item_nature, 'not-fill-out': !item_nature }">
                                                                    <option value="" disabled selected>{{
                                                                        translate('nature') }}
                                                                    </option>
                                                                    <option value="Document">Document</option>
                                                                    <option value="Commercial">Commercial</option>
                                                                    <option value="Gift">Gift</option>
                                                                    <option value="Sample">Sample</option>
                                                                    <option value="Personal use">Personal use</option>
                                                                    <option value="Personal effects">Personal effects
                                                                    </option>
                                                                    <option value="Repair & return">Repair & return</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="text" class="form-control"
                                                                    v-model="item_description"
                                                                    :placeholder="translate('remark')"
                                                                    :class="{ 'fill-out': item_description, 'not-fill-out': !item_description }">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="text" class="form-control" v-model="item_code"
                                                                    :placeholder="translate('hs_code')"
                                                                    :class="{ 'fill-out': item_code, 'not-fill-out': !item_code }">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="number" class="form-control"
                                                                    v-model="item_number"
                                                                    :placeholder="translate('n_items')"
                                                                    :class="{ 'fill-out': item_number, 'not-fill-out': !item_number }"
                                                                    @input="validateItemsNumber">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div class="form-outline">
                                                                <label></label>
                                                                <input type="number" class="form-control"
                                                                    v-model="item_price"
                                                                    :placeholder="translate('unit_price')"
                                                                    :class="{ 'fill-out': item_price, 'not-fill-out': !item_price }"
                                                                    @input="validateItemsPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </template>



                                        <div style="padding: 15px; padding-bottom: 0px;">
                                            <div class="form-group col-12 p-3"
                                                style="margin-top: 15px; background-color: #F5F5F5; border: 2px solid #E5097F; border-radius: 5px;">

                                                <div class="form-group mb-4 col-12" style="margin-top: 5px;">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault559" v-model="safe_parcel"
                                                            style="cursor: pointer;"
                                                            :style="safe_parcel ? { backgroundColor: '#E5097F', border: '#E5097F' } : { backgroundColor: 'darkgray', border: '#E5097F' }">
                                                        <label class="form-check-label" for="flexCheckDefault559"
                                                            style="color: #E5097F; padding: 2px; text-align: justify; border-radius: 10px; cursor: pointer; font-weight: bold;">
                                                            {{ translate('safe_parcel_text') }}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="mt-3 ms-2">
                                                    <img src="../assets/img/dangerous_products.svg" class="img-fluid">
                                                    <div style="color:#E5097F" class="mt-2">
                                                        <span @click="openProductsList"
                                                            style="font-weight: 600; text-decoration: underline; cursor: pointer;">{{
                                                                translate('safe_parcel_text_sntc2') }}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-4 col-12" style="margin-top: 30px;">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault560" v-model="read_conditions"
                                                            style="cursor: pointer;"
                                                            :style="read_conditions ? { backgroundColor: '#E5097F', border: '#E5097F' } : { backgroundColor: 'darkgray', border: '#E5097F' }">
                                                        <label class="form-check-label" for="flexCheckDefault560"
                                                            style="color: #E5097F; padding: 2px; text-align: justify; border-radius: 10px; cursor: pointer;">
                                                            <span>{{ translate('read_conditions_text_sntc1') }}</span> <span
                                                                style="text-decoration: underline;">{{
                                                                    translate('read_conditions_text_sntc2') }}</span> <span>{{
        translate('read_conditions_text_sntc3') }}</span> <span
                                                                @click="openConditionsPage"
                                                                style="text-decoration: underline;">{{
                                                                    translate('read_conditions_text_sntc4') }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="text-align: center;">
                                            <template
                                                v-if="service_point_id && description && read_text && safe_parcel && read_conditions">
                                                <button type="button" class="btn btn-next-active btn-lg btn-block"
                                                    style="width: 40%; height: 40px; font-size: 18px; padding: 0;"
                                                    @click="getDelivery_time">
                                                    {{ translate('next') }}
                                                </button>
                                            </template>

                                            <template v-else>
                                                <button type="button" class="btn btn-next-deactive btn-lg btn-block"
                                                    style="width: 40%; height: 40px; font-size: 18px; padding: 0;"
                                                    @click="checkFields2">
                                                    {{ translate('next') }}
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mb-4">
                        <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                            <div class="card-header py-3"
                                style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white;font-weight: bold;"><i class="fa fa-bars"
                                        aria-hidden="true"></i> {{ translate('summary') }}</h5>
                            </div>
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <template v-if="$store.state.shipment.country">
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/new_order" style="color: #E5097F;"><i class="fa fa-check"
                                                    aria-hidden="true" style="margin-right: 5px;"></i> {{
                                                        translate('shipping_route_2') }} {{
        new_country }})</router-link>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/new_order" class="link-secondary"> {{
                                                translate('shipping_route') }}</router-link>
                                        </li>
                                    </template>
                                    <template v-if="$store.state.shipment.sender">
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/sender_information" style="color: #E5097F;"><i
                                                    class="fa fa-check" aria-hidden="true" style="margin-right: 5px;"></i>
                                                {{ translate('sender_information') }}
                                            </router-link>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/sender_information" class="link-secondary"> {{
                                                translate('sender_information') }}</router-link>
                                        </li>
                                    </template>
                                    <template v-if="$store.state.shipment.receiver.first_name">
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/receiver_information" style="color: #E5097F;"><i
                                                    class="fa fa-check" aria-hidden="true" style="margin-right: 5px;"></i>
                                                {{ translate('receiver_information') }}
                                            </router-link>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/receiver_information" class="link-secondary">
                                                {{ translate('receiver_information') }}
                                            </router-link>
                                        </li>
                                    </template>
                                    <template v-if="$store.state.shipment.description">
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/shipment-information" style="color: #E5097F;"><i
                                                    class="fa fa-check" aria-hidden="true" style="margin-right: 5px;"></i>
                                                {{ translate('shipment_info') }}
                                            </router-link>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                            <router-link to="/import/shipment-information" class="link-secondary">
                                                {{ translate('shipment_info') }}
                                            </router-link>
                                        </li>
                                    </template>

                                    <template v-if="this.$store.state.shipment.country === 'BE'">
                                    <template v-if="home_collection">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-home"></i> {{
                                                    translate('home_collection') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;">{{ home_collection_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-if="printer_option">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                            style="margin-top: 0px;">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-print"></i> {{
                                                    translate('printer') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;">{{ printer_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                </template>

                                    <template v-if="insure_my_parcel">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                            style="margin-top: 0px;">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-check-circle"
                                                        aria-hidden="true"></i> {{ translate('assurance') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;">{{ assurance_amount }} &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                            style="margin-top: 0px;">
                                            <div>
                                                <strong style="color: red;"><i class="fa fa-exclamation-triangle"
                                                        aria-hidden="true"></i> {{ translate('no_insurance') }}</strong>
                                            </div>
                                        </li>
                                    </template>

                                    <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                        style="margin-top: 2px;">
                                        <div>
                                            <strong style="color: #E5097F; font-weight: bold;">{{ translate('total_amount')
                                            }}</strong>
                                            <p class="mb-0" style="color: gray; font-weight: 600;">({{
                                                translate('including_vat') }} {{ VAT }}%)
                                            </p>
                                        </div>
                                        <template v-if="this.$store.state.shipment.country === 'BE'">
                                            <template v-if="home_collection">
                                            <template v-if="printer_option">
                                                <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                    parseFloat(home_collection_amount) +
                                                    parseFloat(printer_amount)).toFixed(2) }} &euro;</strong></span>
                                            </template>
                                            <template v-else>
                                                <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                    parseFloat(home_collection_amount)).toFixed(2) }}
                                                        &euro;</strong></span>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                        </template>
                                        </template>
                                        <template v-else>
                                            <span><strong style="color: #E5097F;">{{ price }} <span>&euro;</span></strong></span>
                                        </template>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import '@vuepic/vue-datepicker/dist/main.css';
import Swal from 'sweetalert2'
import ProcessTimeLine2 from '@/components/ProcessTimeLine2.vue';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    components: {
        ProcessTimeLine2,
        Datepicker
    },
    setup() {
        const date = ref();
        const dateFormat = 'dd-MM-yyyy';

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Check if the current hour is past 18:00
        const currentHour = new Date().getHours();
        const isPast17 = currentHour >= 17;

        if (isPast17) {
            today.setDate(today.getDate() + 1);
        }

        // Calculate the disabled dates (weekends)
        const disabledDates = (date) => {
            const dayOfWeek = date.getDay();
            return dayOfWeek === 0 || dayOfWeek === 6;
        };

        const maxDate = new Date(today);
        maxDate.setDate(maxDate.getDate() + 7);

        return {
            date,
            dateFormat,
            today,
            maxDate,
            disabledDates
        };
    },
    data() {
        return {
            new_country: "",
            price: "",
            description: "",
            package_type: "",
            VAT: "",
            weight: "",
            printer: "",
            insure_my_parcel: "",
            home_collection: "",
            printer_option: "",
            address_search: "",
            servicepoint_address: "",

            commercial_value: "",
            item_nature: "",
            item_description: "",
            item_code: "",
            item_number: "",
            item_price: "",
            service_collection: "",

            read_text: "",
            safe_parcel: "",
            read_conditions: "",

            service_point_id: "",
            service_points: [],

            assurance_amount: "",
            home_collection_amount: "",
            printer_amount: "",
            old_price2: "",

            change_address: "",

            isLoading: false


        }
    },
    async mounted() {
        document.title = 'Shipment Information | Eurekali'

        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.getAssurance()
        this.getHomeCollection()
        this.getPrinter()

        if (this.$store.state.shipment.address_search) {
            this.address_search = this.$store.state.shipment.address_search
        } else {
            this.address_search = this.$store.state.shipment.sender.street + " " + this.$store.state.shipment.sender.number + ", " + this.$store.state.shipment.sender.postal_code + " " + this.$store.state.shipment.sender.city + ", " + this.$store.state.shipment.sender.state + ", " + this.$store.state.shipment.sender.country
        }
        this.new_country = this.$store.state.shipment.country
        this.price = this.$store.state.shipment.price
        this.package_type = this.$store.state.shipment.package_type
        this.weight = this.$store.state.shipment.weight
        this.VAT = this.$store.state.shipment.VAT
        this.printer = this.$store.state.shipment.printer
        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel
        this.home_collection = this.$store.state.shipment.home_collection
        this.printer_option = this.$store.state.shipment.printer_option
        this.service_collection = !this.home_collection
        this.servicepoint_address = this.$store.state.shipment.servicepoint_address

        this.item_nature = this.$store.state.shipment.proforma.item_nature
        this.item_description = this.$store.state.shipment.proforma.item_description
        this.item_code = this.$store.state.shipment.proforma.item_code
        this.item_number = this.$store.state.shipment.proforma.item_number
        this.item_price = this.$store.state.shipment.proforma.item_price


        this.read_text = this.$store.state.shipment.read_text
        this.safe_parcel = this.$store.state.shipment.safe_parcel
        this.read_conditions = this.$store.state.shipment.read_conditions


        this.service_point_id = this.$store.state.shipment.service_point_id

        this.old_price2 = this.$store.state.shipment.old_price2

        if (this.service_point_id != 1 && this.service_point_id && this.address_search) {
            await this.searchservicepoints()
        }

        if (this.$store.state.shipment.proforma.item_price) {
            this.commercial_value = true
        } else {
            this.commercial_value = false
        }


        if (this.$store.state.shipment.description) {
            this.description = this.$store.state.shipment.description
        }

        if (this.address_search && this.servicepoint_address) {
            this.initMap()
        }

        //------------autocomplet---------------------
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: ["be"] });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            let country = '';
            let postalCode = '';
            let state = '';
            let city = '';
            let number = '';
            let street = '';

            for (const component of place.address_components) {
                if (component.types.includes("country")) {
                    country = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    postalCode = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                }
                if (component.types.includes("locality")) {
                    city = component.long_name;
                }
                if (component.types.includes("street_number")) {
                    number = component.long_name;
                }
                if (component.types.includes("route")) {
                    street = component.long_name;
                }
            }

            this.address_search = street + " " + number + ", " + postalCode + " " + city + ", " + state + ", " + country;
        });



        if (this.$store.state.shipment.VAT === '0' && this.$store.state.shipment.weight > 0.5) {
            this.commercial_value = true
        }

        if (this.$store.state.shipment.express) {
            this.setAddress_search()
            await this.searchservicepoints();

            var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
            autocomplete.setComponentRestrictions({ country: ["be"] });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (!place.address_components) {
                    return;
                }

                let country = '';
                let postalCode = '';
                let state = '';
                let city = '';
                let number = '';
                let street = '';

                for (const component of place.address_components) {
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                    }
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("street_number")) {
                        number = component.long_name;
                    }
                    if (component.types.includes("route")) {
                        street = component.long_name;
                    }
                }

                this.address_search = street + " " + number + ", " + postalCode + " " + city + ", " + state + ", " + country;
            });
        }
    },
    watch: {
        servicepoint_address(newValue, oldValue) {
            if (this.address_search && !this.isLoading) {
                this.initMap()
            }
        },
        read_text(newValue, oldValue) {
            if (this.read_text) {
                if(this.$store.state.shipment.country === 'BE'){
                    this.service_point_id = 1
                }
                if(this.$store.state.shipment.country === 'MA'){
                    this.service_point_id = 212
                }

                this.servicepoint_address = ""
                this.service_collection = false
                this.change_address = false
            } else {
                if (this.service_point_id == 1) {
                    this.service_point_id = ""
                }
                this.service_collection = true
                this.printer_option = false
            }
        },
        service_collection(newValue, oldValue) {
            if (this.service_collection) {
                this.home_collection = false
                this.change_address = true
            } else {
                this.home_collection = true
                this.change_address = false
            }
        },
        commercial_value(newValue, oldValue) {
            if (this.commercial_value) {
                if (!this.item_description) {
                    this.item_description = this.description
                }
            }
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        specialPostalCodes() {
            return [1000, 1030, 1040, 1050, 1060, 1070, 1080, 1081, 1082, 1083, 1090, 1140, 1150, 1160, 1170, 1180, 1190, 1200];
        },
        validinformation() {
            if (this.address_search && this.servicepoint_address) {
                return true
            } else {
                return false
            }
        }
    },
    async created() {
        if (!this.$store.state.shipment.country || !this.$store.state.shipment.package_type || !this.$store.state.shipment.price) {
            this.$router.push('/import/new_order')
        }

        await this.createssearchservice();


        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: ["be"] });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            let country = '';
            let postalCode = '';
            let state = '';
            let city = '';
            let number = '';
            let street = '';

            for (const component of place.address_components) {
                if (component.types.includes("country")) {
                    country = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    postalCode = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                }
                if (component.types.includes("locality")) {
                    city = component.long_name;
                }
                if (component.types.includes("street_number")) {
                    number = component.long_name;
                }
                if (component.types.includes("route")) {
                    street = component.long_name;
                }
            }

            this.address_search = street + " " + number + ", " + postalCode + " " + city + ", " + state + ", " + country;
        });

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        chooseHomeCollection() {
            this.home_collection = true
        },
        chooseserviceCollection() {
            this.service_collection = true
        },
        chooseAssurance() {
            this.insure_my_parcel = true
            this.price = (parseFloat(this.old_price2) + parseFloat(this.assurance_amount)).toFixed(2)
        },
        unchooseAssurance() {
            this.insure_my_parcel = false
            this.price = this.price = this.old_price2
        },
        choosePrinter() {
            this.printer_option = true
        },
        unchoosePrinter() {
            this.printer_option = false
        },
        chooseCommercialvalue() {
            this.commercial_value = true
        },
        unchooseCommercialvalue() {
            this.commercial_value = false
        },
        updateServicepoint_address() {
            const selectedServicePoint = this.service_points.find(
                (service_point) => service_point.id === this.service_point_id
            );

            if (selectedServicePoint) {
                this.servicepoint_address = selectedServicePoint.address;
            } else {
                this.servicepoint_address = '';
            }
        },
        async createssearchservice() {
            this.isLoading = true;
            let addressc = ""
            if (this.$store.state.shipment.address_search) {
                addressc = this.$store.state.shipment.address_search
            } else {
                addressc = this.$store.state.shipment.sender.street + " " + this.$store.state.shipment.sender.number + ", " + this.$store.state.shipment.sender.postal_code + " " + this.$store.state.shipment.sender.city + ", " + this.$store.state.shipment.sender.state + ", " + this.$store.state.shipment.sender.country
            }
            const user_address = addressc

            const dataserv = {
                'address': user_address,
                'postal_code': this.$store.state.shipment.sender.postal_code,
                'origin_country' : this.$store.state.shipment.origin_country
            }

            await axios.post('/api/v1/searchservicepoints/', dataserv)
                .then(response => {
                    this.service_points = response.data['nearest_service_points'];
                })
                .catch(error => {
                    console.error(error);
                });

            this.isLoading = false;
        },
        async handler(event) {
            event.preventDefault()
            this.setAddress_search()
            await this.searchservicepoints();

            var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
            autocomplete.setComponentRestrictions({ country: ["be"] });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (!place.address_components) {
                    return;
                }

                let country = '';
                let postalCode = '';
                let state = '';
                let city = '';
                let number = '';
                let street = '';

                for (const component of place.address_components) {
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                    }
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("street_number")) {
                        number = component.long_name;
                    }
                    if (component.types.includes("route")) {
                        street = component.long_name;
                    }
                }

                this.address_search = street + " " + number + ", " + postalCode + " " + city + ", " + state + ", " + country;
            });
        },
        async searchservicepoints() {
            this.isLoading = true;
            const user_address = this.address_search

            const dataserv = {
                'address': this.$store.state.shipment.express ? "postal code " + this.$store.state.shipment.s_postal_code + " " + this.$store.state.shipment.country : user_address,
                'postal_code': this.$store.state.shipment.sender.postal_code,
                'origin_country' : this.$store.state.shipment.origin_country
            }
            await axios.post('/api/v1/searchservicepoints/', dataserv)
                .then(response => {
                    this.service_points = response.data['nearest_service_points'];
                })
                .catch(error => {
                    console.error(error);
                });
            this.isLoading = false;
        },
        async getDelivery_time() {
            this.showAlert()
        },
        showAlert() {
            if (!this.insure_my_parcel) {
                Swal.fire({
                    icon: 'warning',
                    title: this.translate('important_notice'),
                    text: this.translate('alert_assurance_message'),
                    showCancelButton: true,
                    confirmButtonText: this.translate('add_assurance'),
                    cancelButtonText: this.translate('without_assurance'),
                    confirmButtonColor: '#4BB543',
                    cancelButtonColor: '#d33',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.insure_my_parcel = true
                        this.function98(true)
                        this.setProforma()
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        this.insure_my_parcel = false
                        this.function98(false)
                        this.setProforma()
                    }

                });
            } else {
                this.setProforma()
            }
        },
        async getAssurance() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/assurance/latest/?country='+this.$store.state.shipment.country)
                .then(response => {
                    this.assurance_amount = response.data['assurance_amount']
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getPrinter() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/printer/latest/')
                .then(response => {
                    this.printer_amount = response.data['printer_amount']
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getHomeCollection() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/home-collection/latest/')
                .then(response => {
                    this.home_collection_amount = response.data['home_collection_amount']
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        function99() {
            if (!this.insure_my_parcel) {
                this.price = (parseFloat(this.price) + parseFloat(this.assurance_amount)).toFixed(2)
            } else {
                this.price = this.old_price2
            }

        },
        function98(cas) {
            if (cas) {
                this.price = (parseFloat(this.price) + parseFloat(this.assurance_amount)).toFixed(2)
            } else {
                this.price = this.old_price2
            }

        },
        generateTimeOptions() {
            const options = [];

            if (new Date().getHours() > 17) {
                for (let hour = 8 + 2; hour < 18; hour++) {
                    if (hour >= 10) {
                        options.push(`${hour}:00`);
                        options.push(`${hour}:30`);
                    }
                }
            } else {
                if (new Date().getHours() + 2 >= 17) {
                    options.push(`${17}:00`);
                } else {
                    for (let hour = new Date().getHours() + 2; hour < 18; hour++) {
                        if (hour >= 10) {
                            options.push(`${hour}:00`);
                            if (hour != 17) {
                                options.push(`${hour}:30`);
                            }
                        }
                    }
                }
            }

            return options;
        },
        generateTimeOptions2() {
            const options = [];

            if (this.ready_time) {
                const selectedHour = parseInt(this.ready_time.split(':')[0]);

                if (selectedHour + 2 >= 17) {
                    options.push(`${17}:30`);
                } else {
                    for (let hour = selectedHour + 2; hour < 18; hour++) {

                        options.push(`${hour}:00`);
                        options.push(`${hour}:30`);

                    }
                }
            }

            return options;
        },
        checkFields2() {

            if (!this.description) {
                toast({
                    message: this.translate('alert18'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'top-right',
                })
            }


            if (!this.read_text) {
                toast({
                    message: this.translate('alert20'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'top-right',
                })
            }

            if (!this.read_conditions) {
                toast({
                    message: this.translate('read_conditions_alert'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'top-right',
                })
            }

            if (!this.safe_parcel) {
                toast({
                    message: this.translate('safe_parcel_alert'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'top-right',
                })
            }

        },
        async setProforma() {
            console.log('setProforma')

            if (this.commercial_value) {
                if (this.item_nature && this.item_description && this.item_number && this.item_price) {
                    const item_nature = this.item_nature
                    const item_description = this.item_description
                    const item_code = this.item_code
                    const item_number = this.item_number
                    const item_price = this.item_price

                    this.$store.commit('setProforma', {
                        item_nature: item_nature,
                        item_description: item_description,
                        item_code: item_code,
                        item_number: item_number,
                        item_price: item_price,
                    })

                    await this.setPrice()
                    await this.setServicePoint()
                    this.setread_text()
                    this.setsafe_parcel()
                    this.setread_conditions()
                    this.setAddress_search()
                    this.setServicepoint_address()
                    await this.setDimensions()
                } else {
                    toast({
                        message: this.translate('alert21'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 4000,
                        position: 'top-right',
                    })
                }
            } else {
                const item_nature = ""
                const item_description = ""
                const item_code = ""
                const item_number = ""
                const item_price = ""

                this.$store.commit('setProforma', {
                    item_nature: item_nature,
                    item_description: item_description,
                    item_code: item_code,
                    item_number: item_number,
                    item_price: item_price,
                })

                await this.setPrice()
                await this.setServicePoint()
                this.setread_text()
                this.setsafe_parcel()
                this.setread_conditions()
                this.setAddress_search()
                this.setServicepoint_address()
                await this.setDimensions()
            }



        },
        async setServicePoint() {
            console.log('setServicePoint')
            const service_point_id = this.service_point_id
            this.$store.commit('setServicePoint', service_point_id)
        },
        async setread_text() {
            console.log('setread_text')
            const read_text = true
            this.$store.commit('setread_text', read_text)
        },
        async setsafe_parcel() {
            console.log('setsafe_parcel')
            const safe_parcel = true
            this.$store.commit('setsafe_parcel', safe_parcel)
        },
        async setread_conditions() {
            console.log('setread_conditions')
            const read_conditions = true
            this.$store.commit('setread_conditions', read_conditions)
        },
        async setPrice() {
            console.log('setPrice')
            const price = this.price
            this.$store.commit('setPrice', price)
        },
        setAddress_search() {
            console.log('setAddress_search')
            const address_search = this.address_search
            this.$store.commit('setAddress_search', address_search)
        },
        setServicepoint_address() {
            console.log('setServicepoint_address')
            const servicepoint_address = this.servicepoint_address
            this.$store.commit('setServicepoint_address', servicepoint_address)
        },
        async setDimensions() {
            if (this.description) {
                console.log('setDimensions')

                const description = this.description
                const printer = this.printer
                const insure_my_parcel = this.insure_my_parcel
                const assurance_amount = this.assurance_amount
                const printer_amount = this.printer_amount
                const printer_option = this.printer_option
                const home_collection = this.home_collection
                const home_collection_amount = this.home_collection_amount


                this.$store.commit('setDimensions', {
                    description: description,
                    printer: printer,
                    insure_my_parcel: insure_my_parcel,
                    assurance_amount: assurance_amount,
                    printer_amount: printer_amount,
                    printer_option: printer_option,
                    home_collection: home_collection,
                    home_collection_amount: home_collection_amount
                })
                this.$router.push('/import/validate')
            } else {
                toast({
                    message: this.translate('alert22'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'top-right',
                })
            }
        },
        validateItemsNumber() {
            if (this.item_number <= 0) {
                this.item_number = 1;
            }
        },
        validateItemsPrice() {
            if (this.item_price < 0) {
                this.item_price = 1;
            }
        },
        initMap() {
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();

            const map = new google.maps.Map(document.getElementById("map"), {
                zoom: 7,
                center: { lat: 50.8503, lng: 4.3517 }
            });
            directionsRenderer.setMap(map);

            this.calculateAndDisplayRoute(directionsService, directionsRenderer);
        },
        calculateAndDisplayRoute(directionsService, directionsRenderer) {
            directionsService.route(
                {
                    origin: this.$store.state.shipment.express ? "postal code " + this.$store.state.shipment.s_postal_code + " " + this.$store.state.shipment.country : this.address_search,
                    destination: this.servicepoint_address,
                    travelMode: google.maps.TravelMode.DRIVING
                },
                (response, status) => {
                    if (status === "OK") {
                        directionsRenderer.setDirections(response);
                    } else {

                    }
                }
            );
        },
        /*openInGoogleMaps() {
            const address = this.servicepoint_address;
            const encodedAddress = encodeURIComponent(address);
            const url = `https://www.google.com/maps?q=${encodedAddress}`;
            window.open(url, "_blank");
        }*/
        openInGoogleMaps() {
            event.preventDefault();
            const startAddress = this.$store.state.shipment.express ? "postal code " + this.$store.state.shipment.s_postal_code + " " + this.$store.state.shipment.country : this.address_search;
            const endAddress = this.servicepoint_address
            const encodedStartAddress = encodeURIComponent(startAddress);
            const encodedEndAddress = encodeURIComponent(endAddress);

            const url = `https://www.google.com/maps/dir/${encodedStartAddress}/${encodedEndAddress}`;
            window.open(url, "_blank");
        },
        openProductsList() {
            const urlToOpen = "https://eurekali-point.com/products";
            const newWindow = window.open(urlToOpen, '_blank');
            newWindow.focus();
        },
        openConditionsPage() {
            const urlToOpen = "https://eurekali-point.com/conditions";
            const newWindow = window.open(urlToOpen, '_blank');
            newWindow.focus();
        }
    }
}
</script>
  
  
<style>
.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}

.btn-next-active {
    width: 100%;
    margin-top: 20px;
    background-color: #E5097F;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-active:hover {
    background-color: #9b0656;
    color: white;
}

.btn-next-deactive {
    width: 100%;
    margin-top: 20px;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-deactive:hover {
    background-color: gray;
    color: white;
}

.with-height {
    height: 400px;
}

.without-height {
    height: 0;
}

.display-class {
    display: block;
}

.undisplay-class {
    display: none;
}

.custom-radio {
    margin-top: 15px;
}

.custom-radio:hover {
    background-color: #FEEEF6;
    border: 10px double #E5097F;
}

.shipping-option {
    background-color: #F5F5F5;
    padding-left: 30px;
    padding-right: 5px;
    padding-top: 20px;
    margin-top: 15px;
    border: 1px solid black;
    color: #E5097F;
    border-radius: 10px;
    cursor: pointer;
}

.shipping-option:hover {
    background-color: #FEEEF6;
    border: 2px solid #F283BE;
}

.checked-option {
    background-color: #FEEEF6;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    margin-top: 15px;
    border: 2px solid #F283BE;
    color: #E5097F;
    border-radius: 10px;
    cursor: pointer;
}

.box1 {
    margin-right: 10px;
}

.box2 {
    margin-left: 10px;
}


@media (max-width: 576px) {
    .box1 {
        margin-right: 0px;
    }

    .box2 {
        margin-left: 0px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.attention {
    animation: pulse 1s infinite alternate;
}</style>