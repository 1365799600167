<template>
    <!--
    <div class="container process-timeline d-flex align-items-center justify-content-center"
        style="margin-bottom: 20px; margin-top: 10px;">

        <div class="row">

            <div class="progress-steps laptop">
                <ul class="d-flex justify-content-between list-unstyled">
                    <li class="step not-completed" style="background-color: #9b0656;">
                        {{ translate('ship_serv_export') }}
                    </li>
                    <li @click="submitRoute" class="step completed">
                        {{ translate('ship_serv_import') }}
                    </li>
                </ul>
            </div>
            <div class="progress-steps mobile" style="margin-bottom: 10px;">
                <ul class="d-flex justify-content-between list-unstyled">
                    <li class="step not-completed" style="background-color: #9b0656;">
                        {{ translate('ship_serv_export') }}
                    </li>
                    <li @click="submitRoute" class="step completed">
                        {{ translate('ship_serv_import') }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    -->
</template>



<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    data() {
        return {

            shipment: {},

            sender: {
                number: "",
                first_name: "",
                last_name: "",
                street: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                email: "",
                phone: ""
            },
            receiver: {
                number: "",
                street: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                email: "",
                phone: ""
            },
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Progress | Eurekali'

        this.shipment = this.$store.state.shipment

        this.receiver = this.$store.state.shipment.receiver
        this.sender = this.$store.state.shipment.sender

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        submitRoute(){
            this.$store.commit('clearShipment')
            this.$router.push("/import/new_order")
        }
    }
}
</script>



<style scoped>
.progress-steps ul {
    border: 1px solid #fff;
    border-radius: 30px;
    overflow: hidden;
}

.step {
    text-align: center;
    padding: 8px 16px;
    background-color: #E5097F;
    color: white;
    font-weight: bold;
    border-left: 1px solid #fff;
}

.step:first-child {
    border-left: none;
}

.completed {
    background-color: #E5097F;
    cursor: pointer;
}

.inprogress {
    background-color: #E981A4;
    cursor: pointer;
}

.not-completed {
    background-color: #aaa;
    cursor: not-allowed;
}

.step img {

    scale: 0.9;
    vertical-align: middle;
}

.mobile {
    display: none;
}

@media (max-width: 576px) {

    .step {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .laptop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
</style>
